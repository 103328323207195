.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App {
  background-color: white;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /*width: 100vw;*/
  /*height: 100vh;*/
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

p.error-msg {
  color: red;
  font-size: 12px;
}

.dc-heading-invoice {
  color: blue;
  font-weight: 600;
}

.circleCustom {
  background: rgb(116, 116, 254);
  width: 16vw;
  height: 16vw;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.circleCustom > div {
  text-align: center;
}

.required:after {
  content: "*\00a0";
  color: red;
}

.alert .btn-close {
  /*border: 1px solid rgb(175, 169, 169);*/
  color: hsl(0, 93%, 48%);
  text-align: center;
  /*display: inline-block;*/
  font-size: 8px;
  border-radius: "2px";
}

.table-row-heading {
  color: #55585a !important;
}

.container-spinner {
  width: 100%;
  height: 300px;
  /*border: 1px solid #ff8899;*/
  /*display: grid;*/
}

.vertical-center-spinner {
  /*background-color: crimson;*/
  color: #fff;
  /*border: 1px solid;*/
  padding: 10px;
  width: 100px;
  outline: none;
  margin: auto;
}

.checkCopy input[type='checkbox']:after {
  width: 17px;
  height: 17px;
  border-radius: 3px;
  top: -2px;
  left: -1px;
  position: relative;
  /*background-color: #e6f7e6;*/
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid rgb(2, 69, 156);
}

/*
input[type='checkbox']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #e6f7e6;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid rgb(240, 101, 42);
}
*/
.modal-width {
  width: 70%!important;
}
